
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  background-color: #fff;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/// 
/// 
/// 
/// 
$menuColor:#0b0b0c;
$menuBgColor: #f2f2f2; // #1B5264
.menu-btn-1 {
  height: 32px;
  width: 40px;
  cursor: pointer;
  position: fixed;
  z-index: 10;
  text-align: left;
  top: 5px;
  left: 5px;
  background-color: $menuBgColor;
  padding: 10px;
  border-radius: 8px;
  scale: 0.7;
}

  .menu-btn-1 span,
  .menu-btn-1 span::before,
  .menu-btn-1 span::after {
      background: $menuColor;
      border-radius: 3px;
      content: '';
      position: absolute;
      width: 40px;
      height: 6px;         
      margin-top: 13px; 

      -webkit-transition: .2s ease-in-out;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      transition: .2s ease-in-out;
  }

  .menu-btn-1 span::before {
      margin-top:-12px;
  }

  .menu-btn-1 span::after {
      margin-top:12px;
  }

  .menu-btn-1.active span {
      background: transparent;
  }

  .menu-btn-1.active span::before {
      margin-top: 0;

      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  .menu-btn-1.active span::after {
      margin-top: 0;

      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }

.backButton{
  background-color: $menuBgColor;
  display: inline-block;
  padding: 5px;
  position: fixed;
  top: 12px;
  left: 71px;
  border-radius: 17px;
  font-size: 19px;
  letter-spacing: 1px;
  z-index: 1000;
  /* border: 1px solid #000; */
  line-height: 7px;
  padding: 9px 6px 10px 8px;
  cursor: pointer;

  svg {
    margin-bottom: -2px;
    stroke-width: 3px;
    display: inline-block;
    width: 20px;
    margin-right: 5px;
  }

  span {
    font-weight: bold;
    font-size: 22px;
    padding-right: 9px;
  }
}
@media (max-width: 650px) {
  .backButton span{ 
    display: none;
  }
}

.headerScore{
  background-color: $menuBgColor;
  display: inline-block;
  padding: 5px;
  position: fixed;
  top: 12px;
  right: 12px;
  border-radius: 5px;
  font-size: 19px;
  letter-spacing: 2px;
  z-index: 1000;

  svg {
    margin-bottom: -4px;
  }

  span {
    font-weight: bold;
    font-size: 22px;
  }
}


/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////








$height: 48px;

html, body, #root{
  width: 100%;
  height: 100%;
  font-family: Neucha, Arial, Helvetica, sans-serif;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
}
*.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.left-navigation {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: -1px;
    width: 240px;
    padding-top: $height;
    //background: $menuBgColor;
    // border-right: 1px solid #2D2D2D;
    letter-spacing: 1px;
    transition: transform .1s ease-out;
    transform: translateX(-245px);
    overflow-y: scroll;

    li {
      margin: 5px 5px 5px 15px;
      display: block;
      text-align: left;
      a{
        padding: 8px;
        svg{
          display: inline-block;
          margin-right: 10px;
          margin-bottom: -4px;
          width: 24px;
          height: 24px;      
        }
        cursor: pointer;
        display: block;
        color: $menuColor;
        font-size: 21px;
        text-decoration: none;
        border-radius: 4px;

        &:hover{
          background: $menuBgColor;
        }
      }
    }
}

.show_left_nav .left-navigation {
  transform: translateX(0);
}
.content{
  transition: transform .1s ease-out;
  min-height: 100vh;
}

h1 {
  font-family: "Caveat", Neucha, cursive, sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  padding: 6px 0 48px;
  line-height: 33px;
  font-size: 33px;
  color: #000;
  letter-spacing: 1px;

  &.up{
    text-transform: uppercase;
    font-size: 26px;
  }
}

img.avatar{
  width: 45px;
  height: 45px;;
  margin-bottom: -10px;
  margin-right: 5px;
}



.overlayZ{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 1000;
  background: rgba(0,0,0);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.show_left_nav .content{
  transform: translateX(240px);
  position: relative;

  .overlayZ{
    opacity: 0.7;
    visibility: visible;
  }
}


#root {
  top: 0;
  left: 0;
  position: relative;
  overflow: visible;
  overflow-x: hidden;
}


.iframe_content{
  position: absolute; 
  top:0; 
  left: 0; 
  width:100%; 
  height: 100%; 
  z-index: 10000;
}


.pageContent{
  max-width: 800px;
  margin: 0 auto;
  li{
    display: inline-block;
    width: 30%;
    padding: 15px 15px 10px;
    box-sizing: border-box;
    text-align: center;
    img{
      width: 100%;
      border: 1px #000 solid;
      border-radius: 5px;
      display: block;
    }
    b {
      height: 30px;
      overflow: hidden;
      display: block;
    }
  }
}


.welcomeScreen{
  .pageContent li a{
    text-decoration: none;
    img{
      border: none;
      max-width: 135px;
      margin: 0 auto 10px;
      scale: 0.95;
      transition: all 0.12s;
    }
    &:hover img{
      scale: 1;
    }
    span {
      font-family: Neucha,Arial,Helvetica,sans-serif;
      font-size: 23px;
      color: #333;
    }
  } 
}


.trackList ul{
  max-width: 800px;
  margin: 0 auto;

  li.trackThumb{
    display: inline-block;
    width: 30%;
    padding: 20px 20px 0;
    box-sizing: border-box;
    position: relative;

    a{
      text-decoration: none;
    }

    b, .author {
      height: 41px;
      overflow: hidden;
      color: #000;
      font-size: 19px;
      line-height: 21px;
      margin-top: 3px;
    }
    .author:hover {
      text-decoration: underline;
    }

  }
}




.trackList ul li img.trackThumbImage{
  width: 100%;
  // border: 1px #5c5e65 solid;
  // border-radius: 5px;
}
.trackList ul li img.trackThumbImage,
.trackList ul li b {
  display: block;
}

.completed{
background-image: url("../../public/static/img/icon-completed-small.png");
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 43px;;
}

.cataloging .trackThumb:hover .trackTitle{
  display: none;
}
.cataloging .author{
  display: none;
}
.cataloging .trackThumb:hover .author{
  display: block;
}

.cataloging .trackThumb .stat{
  display: none;
  position: absolute;
  font-size: 13px;
  top: 2px;
  height: 15px;
  left: 20px;
  text-align: center;
  right: 20px;
  font-size: 15px;
  font-weight: bold;
}
.cataloging .trackThumb:hover .stat{
  display: block;
}

.cataloging .trackThumb .stat span.likes {
  left: 0;
  background-image: url("../../public/static/img/icon-thumbs-up.png");
  color: #8bc243;
  position: absolute;
  padding-left: 16px;
  background-repeat: no-repeat;
}

.cataloging .trackThumb .stat span.played {
  background-image: url("../../public/static//img/icon-play.png");
  color: #000;
  padding-left: 14px;
  background-repeat: no-repeat;
}

.cataloging .trackThumb .stat span.dislikes {
  right: 0;
  background-image: url("../../public/static//img/icon-thumbs-down.png");
  color: #ff7575;
  position: absolute;
  padding-left: 16px;
  background-repeat: no-repeat;
}



.landingScreen {
  max-width: 745px;
  margin: 0 auto;
  padding: 10px;
}
h2{
  font-family: "Caveat", Neucha, cursive, sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 1px;

  font-size: 32px;
  color: #37383a;
  font-weight: 700;
  padding: 10px 0 35px;
  line-height: 34px;
}
h3{
  font-family: "Caveat", Neucha, cursive, sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 1px;
  font-size: 27px;
  color: #37383a;
  font-weight: 700;
  padding: 12px 0;
  line-height: 34px;
}
h5{
  margin-top: 100px;
  font-family: Neucha, cursive, sans-serif;
  font-optical-sizing: auto;
  letter-spacing: 1px;
  font-size: 20px;
  color: #37383a;
  font-weight: 700;
  padding: 12px 0;
  line-height: 29px;
}
.slogan {
  display: inline-block;
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 7px;
  letter-spacing: 1px;
  font-size: 15px;
}
.gameLogo{
  width: 200px;
  border: 1px solid #000;
  border-radius: 20px;
}
.comments{
  padding: 4px 20px;
  max-width: 700px;
  text-align: left;
  font-family: Neucha;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 10px;
}
.hideObj{
  display: none;
}
.showObj{
  display: block;
}