$greyDark: #424c57;
.comments {

  .commentsHeader {
    font-size: 23px;
    //border-bottom: 1px solid #000;
    margin-bottom: 29px;
    padding-bottom: 12px;
    padding-top: 20px;
  }

  .commentFormPreloader{
    height: 90px;
    margin-top: 41px; 
  }

  .commentForm {
    border: 1px solid #dededf;
    padding: 10px 15px 10px;
    // min-height: 172px;
    border-radius: 5px;
    margin-bottom: 64px;
    position: relative;

    .textModifiers {
      position: absolute;
      bottom: -26px;
      left: 0;
      a {
        display: inline-block;
        margin: 0 5px;

        svg {
          width: 15px;
          height: 15px;

          path {
            fill: #8c9aa5; //#8d96b2;
          }
        }
        &:hover {
          svg {
            path {
              fill: #000; //#8d96b2;
            }
          }
        }
      }
    }

    .textarea[contenteditable][placeholder]:empty:before {
      content: attr(placeholder);
      position: absolute;
      color: #8b98a4;
      background-color: transparent;
      font-size: 16px;
    }

    .submitBtn {
      background-color: #1a6aff;
      color: #fff;
      padding: 10px 16px;
      align-items: center;
      font-size: 16px;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      line-height: 24px;
      position: relative;
      border-radius: 5px;
      position: absolute;
      bottom: -52px;
      right: 0;
      &:hover {
        background-color: #1a6aff;
      }
    }

    .textarea {
      resize: none;
      cursor: text;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      border: 0;
      min-height: 66px;
      color: $greyDark;
    }
  }

  .repliesContainer {
    margin-left: 0;
    padding-left: 40px;
    border-left: 3px solid #e9eaee;
    margin-bottom: 33px;
    margin-top: 20px;

    .textarea {
      // min-height: auto;
      min-height: 22px;
    }
  }
  .collapsedCommentForm {
    background-color: #fff;
    padding: 6px 15px;
    border: 1px solid #e9eaee;
    border-radius: 2px;
    font-size: 16px;
    line-height: 32px;
    color: #8b98a4;
    width: 100%;
    display: block;
    text-decoration: none;
    border-radius: 3px;
  }
  .comment {
    position: relative;
    border: solid transparent;
    border-width: 1px 0;
    padding: 9px 15px 14px;
    border-top-color: #e5e5ea;

    .deleteComment {
      opacity: 0;
      font-size: 14px;
      line-height: 20px;
      color: #cd192e;
      position: absolute;
      right: 0;
      bottom: 5px;
      background-color: #fff;
      padding: 4px 8px;
    }

    &:hover {
      .thumbsDown,
      .thumbsUp,
      .replyComment,
      .deleteComment {
        opacity: 1 !important;
      }
    }

    .commentHeader {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .avatar {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .commentAuthor {
        font-weight: 500;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
        font-size: 15px; //18px;
        line-height: 20px;
        color: #1e2022; // #262626; // #737fa0; //#262626;
        white-space: nowrap;
        font-weight: bold;
      }

      .commentDate {
        color: #7c8a98;
        font-size: 14px;
        line-height: 14px;
      }

      .commentRateWrapper {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: -15px;

        .replyComment {
          margin-right: 20px;
          padding-bottom: 3px;
          svg {
            width: 15px;
            path {
              fill: #8a98a4;
            }
          }
          &:hover {
            svg path {
              fill: #000;
            }
          }
        }
        .replyComment {
          opacity: 0;
          svg {
            width: 21px;
          }
        }
        .thumbsDown,
        .thumbsUp {
          opacity: 0;
          svg {
            width: 24px;
          }
        }


        .currentRating {
          display: block;
          padding: 0 10px;
          //color: #8d96b2;
          color: #8b98a4;
          background-color: #e9eaee;
          font-size: 14px;
          line-height: 20px;
          padding: 0 12px;
          min-width: 40px;
          text-align: center;
          margin: 0 4px;
          border-radius: 5px;

          &.positiveRating {
            color: #0a1;
            background-color: #e2f8eb;
          }
          &.negativeRating {
            color: #cd192e;
            background-color: #fff1f1;
          }
        }

        a {
          display: block;

          &.thumbsDown {
            margin-top: 5px;
            &:hover {
              svg path {
                fill: red !important;
              }
            }
          }

          &.thumbsUp {
            margin-top: 2px;
            &:hover {
              svg path {
                fill: #28bc00 !important;
              }
            }
          }
        }
      }
    }
    .commentContent {
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      overflow-wrap: break-word;
      color: $greyDark;
      // font-size: 16px;
      // line-height: 18px;
    }
  }
}
@media (max-width: 800px) {
  .comments {
    .commentsHeader {
      .tabulator {
        margin: 16px 0px 9px;
        float: none !important;
      }
    }
    .commentHeader {
      position: relative;

      .commentAuthor {
        left: 43px;
        position: absolute;
        top: 0;
        max-width: 142px;
      }
      .commentDate {
        color: #4f5464; //#a4a9b8;
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        bottom: 0;
        left: 44px;
      }
      .commentDate .desktopDateView {
        display: none;
      }
    }

    .repliesContainer {
      margin-left: 8px;
      padding-left: 17px;
      padding-right: 10px;
    }
  }
}

@-webkit-keyframes yellow-fade {
  0% {
    background: yellow;
    max-height: 0px;
  }
  100% {
    background: none;
    max-height: 500px;
  }
}
@keyframes yellow-fade {
  0% {
    background: yellow;
    max-height: 0px;
  }
  100% {
    background: none;
    max-height: 500px;
  }
}

.highlightComment {
  overflow: hidden;
  -webkit-animation: yellow-fade 0.5s ease-in 1;
  animation: yellow-fade 0.5s ease-in 1;
}

.tabulator {
  display: flex;
  list-style: none;
  align-content: stretch;
  li {
    flex: 1;
  }

  .tab-link {
    padding: 12px 18px;
    position: relative;
    display: block;
    font-size: 17px;
    color: #21325b;
    text-decoration: none;
    border: 1px solid #d0d1d5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    svg {
      fill: currentColor;
      margin: -4px 5px -4px -3px;
      transform: scale(0.85);
    }
  }

  .tab-item:not(:first-child) .tab-link {
    margin-left: -1px;
  }
  .tab-item:first-child .tab-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .tab-item:last-child .tab-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .tab-item {
    a:hover {
      background-color: #e9ecef;
      //border-color: #dee2e6;
      text-decoration: none;
    }
    &.active a {
      background-color: #21325b !important;
      color: #fff !important;
      border-color: #21325b;
      svg {
        fill: currentColor;
      }
    }
  }
}
.comments .tabulator {
  .tab-link {
    padding: 8px 16px;
    font-size: 15px;
    border-color: #000;
  }
}
@media (max-width: 800px) {
  .tabulator {
    margin: 0 auto 10px;
    width: 100% !important;
    align-items: stretch;

    li {
      flex: 1;
    }
    // .tab-item {
    //   width: 20%;
    // }
    .tab-link {
      font-size: 16px;
      line-height: 19px;
      // height: 76px;
      padding: 7px;
      svg {
        margin: 2px auto !important;
        display: block;
        transform: scale(1);
        &.IELTSExpertIcon {
          margin: 0 auto !important;
        }
      }
      span {
        display: block;
      }
      i {
        display: inline-block !important;
      }
    }
  }
  .comments .commentFormPreloader{
    height: 90px;
    margin-bottom: 42px;
    margin-top: 24px;
  }
}

.comments .textarea:focus-visible{
  outline: none !important;
}